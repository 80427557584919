import {PropsWithChildren} from "react";

type Props = {
    title: string
    noPaddingLeft?: boolean
}

const Section = (props:PropsWithChildren<Props>) =>{
    return (
        <div className={"w-full text-left mt-4"} style={{fontFamily: "Lato"}}>
            <h1  style={{fontFamily: "Orbitron"}} className={"text-4xl font-bold mb-2 text-sectionTitleLight dark:text-sectionTitleDark"}>{props.title}:</h1>
            <div className={`text-xl ${!props.noPaddingLeft&&"pl-2"}`}>{props.children}</div>

        </div>
    )
}

export default Section