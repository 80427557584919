import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    common: {
                        mailCopied: "Mail copied to clipboard!"
                    },
                    presentation:{
                      desc: "Computer Engineer with a specialization in Software Engineering."
                    },
                    aboutMe:{
                        title: "About me",
                        short: "Software developer with a degree in software engineering",
                        main: {
                            p1: "Welcome to my portfolio!",
                            p2: "I am a computer engineer graduated from the University of A Coruña (UDC), specialized in software engineering. I focus on full-stack development and believe I have the ability to easily adapt to different technologies I work with and learn them.",
                            p3: "I also enjoy participating in the creation of new systems, seeking the best methods and technological alternatives to turn ideas into real products, from development to deployment.",
                            p4: "In this space, you can learn more about my background, the projects I have worked on, and the technologies I have experience with.",
                        },
                        downloadCVButton: "Download CV",
                        contactButton: "Contact",
                    },
                    contactForm: {
                        title: "Fill out this form to send me a message, and I will contact you via email.",
                        mailAdv: "If you prefer, you can write to me directly at my email address",
                        name: "Name",
                        mail: "Email",
                        message: "Message",
                        writeYourName: "Enter your name",
                        writeYourMail: "Enter your email address",
                        enterValidMail: "Enter a valid mail address",
                        writeYourMessage: "Enter your message",
                        sendButton: "Send",
                        sendingMail: "Sending email...",
                        messageSent: "The message has been sent successfully.",
                        confirmationMailSent: "A confirmation email has been sent to your email address.",
                        thankYouForYourMessage: "Thank you very much for your message, I will reply as soon as possible.",
                        errorSendingMessage: "It seems there was an error sending the message.",
                        pleaseContactByMail: "Please contact me by email",

                    },
                    studies: {
                        title: "Studies",
                        bach: "High School diploma in Pure Sciences at I.E.S. Sofía Casanova (Ferrol, A Coruña)",
                        uni: "Computer Engineering with a specialization in Software Engineering at UDC (A Coruña)",
                        tfgM: "Distinction in my Final Degree Project (TFG) with my work on the development of the Collaborative Kanban Board."
                    },
                    projects: {
                        title: "Personal projects",
                        myProjects: "My projects",
                        commonButtons: {
                            goToApp: "See more and try",
                        },
                        kanban: {
                            name: "Shared Kanban Board",
                            caption1: "Create and manage Kanban boards focused on SCRUM.",
                            caption2: "Work with other users in the board at real time.",
                            caption3: "Obtain statistics on the work performed and task precedence graphs.",
                        }
                    },
                    workExperience: {
                        title: "Work experience",
                        content: "Nothing at the moment.",
                    },
                    otherData: {
                        title: "Other data",
                        content: {
                            car: "Availability of own car.",
                            location: "Based in Ferrol, A Coruña, Spain.",
                            english: "Intermediate level of English.",
                            extraClass: "Experience giving private computer lessons."
                        },
                    },
                    stack: {
                        title: "Stack",
                        p1: "I believe that many programming and development concepts are cross-cutting across technologies.",
                        p2: "These are some of the technologies I have worked with the most, although I adapt quickly to new tools"

                    },
                    skills: {
                        title: "Skills",
                        p1: "During my studies and beyond, I have developed knowledge in various areas within computing, both in software development and in other fields.",
                        p2: "These are some of the skills I consider most relevant",
                        captions: {
                            requirementsAnalysis: "Analysis and requirements gathering",
                            softwareArchitecture: "Software architecture and system design",
                            backendFrontendProgramming: "Backend and frontend programming",
                            enterpriseApplications: "Enterprise application development",
                            configurationManagement: "Project configuration management",
                            developmentMethodologies: "Development methodologies",
                            designPatterns: "Software design patterns",
                            databaseSystems: "Database systems",
                            relationalDatabases: "Relational databases",
                            mongoDb: "MongoDb",
                            programmingLanguagesTyping: "Programming languages functionality and typing",
                            versionControlGitGitFlow: "Version control with Git and GitFlow",
                            continuousIntegrationJenkinsGithub: "Continuous integration with Jenkins and Github Actions",
                            testing: "Testing",
                            unitIntegrationTests: "Unit and integration test implementation between different layers",
                            testingTechniquesMocking: "Testing techniques: white box/black box, mocking...",
                            deploymentInfrastructure: "Deployment and infrastructure",
                            containerizationDocker: "Containerization with Docker",
                            kubernetesDeployments: "Deployments on Kubernetes",
                            clusterManagement: "All of my domain's infrastructure and projects run on a Kubernetes cluster managed by me",
                            networking: "Networking",
                            networkProtocols: "Network protocols",
                            ciscoPacketTracerCLI: "Knowledge of Cisco Packet Tracer and Cisco CLI",
                            networkConfigurationVlans: "Network, subnet, and VLAN configuration"
                        }
                    }
                }
            },
            es: {
                translation: {
                    common: {
                        mailCopied: "¡Correo copiado al portapapeles!"
                    },
                    presentation: {
                        desc: "Ingeniero Informático con especialidad en ingeniería del software."
                    },
                    aboutMe: {
                        title: "Sobre mí",
                        short: "Desarrollador de software graduado en ingeniería informática.",
                        main: {
                            p1: "¡Bienvenido a mi portfolio!",
                            p2: "Soy un ingeniero informático graduado de la Universidad de A Coruña (UDC), especializado en ingeniería del software. Me enfoco en el desarrollo fullstack y considero que tengo la capacidad de adaptarme fácilmente a las distintas tecnologías con las que trabajo y aprender sobre ellas.",
                            p3: "También me gusta participar en la creación de nuevos sistemas, buscando los mejores métodos y alternativas tecnológicas para convertir las ideas en productos reales, desde el desarrollo hasta el despliegue.",
                            p4: "En este espacio podrás conocer más sobre mi trayectoria, los proyectos en los que he trabajado y las tecnologías con las que tengo experiencia."
                        },
                        downloadCVButton: "Descargar CV",
                        contactButton: "Contactar",
                    },
                    contactForm: {
                        title: "Completa este formulario para enviarme un mensaje y te contactaré por correo electrónico.",
                        mailAdv: "Si lo prefieres, puedes escribirme directamente a mi correo electrónico",
                        name: "Nombre",
                        mail: "Correo",
                        message: "Mensaje",
                        writeYourName: "Escriba su nombre",
                        writeYourMail: "Escriba su dirección de correo electrónico",
                        enterValidMail: "Introduzca una dirección de correo electrónico válida",
                        writeYourMessage: "Escriba su mensaje",
                        sendButton: "Enviar",
                        sendingMail: "Enviando correo...",
                        messageSent: "El mensaje se ha enviado correctamente.",
                        confirmationMailSent: "Se ha enviado un correo de confirmación a tu dirección de email.",
                        thankYouForYourMessage: "Muchas gracias por tu mensaje, te responderé con la mayor brevedad posible.",
                        errorSendingMessage: "Parece que ha habido un error con el envío del mensaje.",
                        pleaseContactByMail: "Por favor, contáctame por correo electrónico.",
                    },
                    studies: {
                        title: "Estudios",
                        bach: "Bachillerato en ciencias puras en I.E.S Sofía Casanova (Ferrol, A Coruña).",
                        uni: "Ingeniería Informática con especialidad en Ingeniería de Software en la UDC (A Coruña).",
                        tfgM: "Matrícula de honor en el TFG con mi trabajo de desarrollo del Tablero Kanban Colaborativo."
                    },
                    projects: {
                        title: "Proyectos personales",
                        myProjects: "Mis proyectos",
                        commonButtons: {
                            goToApp: "Ver más y probar",
                        },
                        kanban: {
                            name: "Tablero Kanban Colaborativo",
                            caption1: "Crea y gestiona tableros Kanban enfocados a SCRUM.",
                            caption2: "Trabaja colaborativamente en tiempo real con otros usuarios.",
                            caption3: "Obtén estadísticas sobre el trabajo realizado y gráficos de precedencia de las tareas.",
                        }
                    },
                    workExperience: {
                        title: "Experiencia laboral",
                        content: "Nada por el momento.",
                    },
                    otherData: {
                        title: "Otros datos de interés",
                        content: {
                            car: "Disponibilidad de coche propio.",
                            location: "Residencia en Ferrol, A Coruña.",
                            english: "Nivel intermedio de inglés.",
                            extraClass: "Experiencia dando clases particulares de informática.",
                        },
                    },
                    stack: {
                        title: "Tecnologías",
                        p1: "Considero que muchos conceptos de programación y desarrollo son transversales a las tecnologías.",
                        p2: "Estas son algunas con las que más he trabajado, aunque me adapto rápidamente a nuevas herramientas"
                    },
                    skills: {
                        title: "Conocimientos",
                        p1: "Durante la carrera y fuera de ella, he desarrollado conocimientos en diversos ámbitos dentro de la informática, tanto en el desarrollo de software como en otros campos.",
                        p2: "Estas son algunas de las habilidades que considero más relevantes",
                        captions: {
                            requirementsAnalysis: "Análisis y toma de requisitos",
                            softwareArchitecture: "Arquitectura software y diseño de sistemas",
                            backendFrontendProgramming: "Programación backend y frontend",
                            enterpriseApplications: "Desarrollo de aplicaciones empresariales",
                            configurationManagement: "Gestión de configuraciones del proyecto",
                            developmentMethodologies: "Metodologías de desarrollo",
                            designPatterns: "Patrones de diseño software",
                            databaseSystems: "Sistemas de bases de datos",
                            relationalDatabases: "Bases de datos relacionales",
                            mongoDb: "MongoDb",
                            programmingLanguagesTyping: "Funcionamiento de los lenguajes de programación y el tipado",
                            versionControlGitGitFlow: "Control de versiones con Git y GitFlow",
                            continuousIntegrationJenkinsGithub: "Integración continua con Jenkins y GitHub Actions",
                            testing: "Testing",
                            unitIntegrationTests: "Implementación de tests de unidad e integración entre las distintas capas",
                            testingTechniquesMocking: "Técnicas de testing: caja blanca/negra, mocking...",
                            deploymentInfrastructure: "Despliegue e infraestructura",
                            containerizationDocker: "Contenedorización con Docker",
                            kubernetesDeployments: "Despliegues en Kubernetes",
                            clusterManagement: "Toda la infraestructura de mi dominio y mis proyectos corre en un clúster de Kubernetes gestionado por mí",
                            networking: "Redes",
                            networkProtocols: "Protocolos de red",
                            ciscoPacketTracerCLI: "Conocimientos en Cisco Packet Tracer y Cisco CLI",
                            networkConfigurationVlans: "Configuración de redes, subredes y VLANs"
                        }
                    }
                }
            }
        }
    });

export default i18n;