import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContactForm from "../ContactForm/ContactForm";
import CopyMail from "./common/CopyMail";

type ContactItemType = {
    icon: JSX.Element,
    text: string|JSX.Element,
    onClick: () => any
}

type ContactItemProps = {
    contactItem: ContactItemType
}

const ContactItem = (props: ContactItemProps) => {
    return (

        <div onClick={props.contactItem.onClick}
            className={"whitespace-nowrap inline-block ml-4 hover:underline hover:cursor-pointer"}>
            {props.contactItem.icon}
            <a className={"whitespace-nowrap ml-1 inline-block "}>{props.contactItem.text}</a>
        </div>

    )
}

const AboutMe = (props:{openModal:()=>any}) => {
    const {t} = useTranslation();
    const [img, setImg] = useState<JSX.Element | null>(null)
    const contactItems: ContactItemType[] = [
        {
            text: <CopyMail iconSize={"sm"}/>,
            icon: <FontAwesomeIcon size={"lg"} icon={faEnvelope}/>,
            onClick: () => {
            }
        },
        {
            text: "Jaime José Corral García",
            icon: <FontAwesomeIcon size={"lg"} icon={faLinkedin}/>,
            onClick: () => {
                window.open("https://www.linkedin.com/in/jaimejcorral", '_blank')
            }
        }
    ]
    useEffect(() => {
        const imgLoaded = <img alt={""} data-aos={"fade-right"} className={"sm:h-72 h-48 sm:inline float-left mr-1"}
                               src={"/img/fotoCuerpo.png"}/>
        setImg(imgLoaded)
    }, [])
    if (img === null)
        return null
    type ContactButton = {
        text: string,
        className: string,
        onClick: () => any
    }

    const contactButtons: ContactButton[] = [
        {text: t("aboutMe.downloadCVButton"),
            className: "dark:bg-aboutMeDownloadButtonDark bg-aboutMeDownloadButtonLight " +
                "dark:text-aboutMeContactButtonTextDark text-aboutMeContactButtonTextLight",
            onClick: ()=>{window.open("/CV Jaime José Corral García.pdf", '_blank')}},
        {text: t("aboutMe.contactButton"),
            onClick: ()=>{
                props.openModal()
            },
            className: "dark:bg-aboutMeContactButtonDark bg-aboutMeContactButtonLight " +
                "dark:text-aboutMeContactButtonTextDark text-aboutMeContactButtonTextLight"},
    ]
    const paragraphs = [t("aboutMe.main.p1"), t("aboutMe.main.p2"), t("aboutMe.main.p3"), t("aboutMe.main.p4")]

    return (
        <div
            className={"w-full shadow shadow-gray-950 bg-aboutMeBgLight dark:bg-aboutMeBgDark flex flex-col items-center p-2"}>
            <div className={"h-full  2xl:max-w-[1536px] w-full "}>
                <img alt={""} className={"lg:h-72 h-48 sm:inline float-left mr-1"}
                     src={"/img/fotoCuerpo.png"}/>
                <div className={"h-full sm:mt-8 mr-4 text-md lg:text-lg  xl:text-xl"} style={{fontFamily: "Handlee"}}>
                    {paragraphs.map((x, i) => <p className="pt-2" key={i}>{x}</p>)}
                </div>
                <div className={"mt-2 inline-block"}>
                    {contactButtons&&contactButtons.map((x, i) =>
                        <button  style={{fontFamily: "Orbitron"}}
                            className={`mt-1 mb-3 ${x.className} p-2 text-xl rounded-xl inline-block 
                            font-bold ml-4 shadow-md shadow-gray-900 hover:brightness-[1.15]`}
                            key={i} onClick={x.onClick}>{x.text}</button>
                    )}
                </div>
            </div>
            <div className={"border-t-2 border-white gap-4 w-full mt-2 text-xl flex justify-center flex-wrap items-center text-left sm:text-center sm:pl-12"}>
                <div>{contactItems.map((x, i) => <ContactItem key={i} contactItem={x}/>)}</div>

            </div>

        </div>
    )
}

export default AboutMe